<template>
  <v-text-field
    type="number"
    v-model="xPartes"
    label="Dividir a área de concessão em X partes"
    :min="min"
    prepend-icon="mdi-numeric-1-box-multiple-outline"
  />
</template>

<script>
export default {
  name: 'FormQuebrarAratBdgdConfig',
  data() {
    return {
      xPartes: 2,
      min: 2
    };
  },
  props: {
    algoritmoConfig: {
      type: Object
    }
  },
  mounted() {
    this.algoritmoConfigAtualizado();
    if (this.algoritmoConfig) this.fillInputs();
  },
  watch: {
    xPartes() {
      this.algoritmoConfigAtualizado();
    }
  },
  methods: {
    algoritmoConfigAtualizado() {
      this.$emit('algoritmoConfigAtualizado', {
        xPartes: Number(this.xPartes),
        min: this.min
      });
    },
    fillInputs() {
      this.xPartes = this.algoritmoConfig.xPartes;
      this.min = this.algoritmoConfig.min;
    }
  }
};
</script>
